import React, { Component } from 'react';
import { Button, Menu, MenuItem, RadioGroup, FormControlLabel, Radio, List, ListItem, Dialog, DialogTitle, DialogContent, DialogActions, TextField, Typography } from '@material-ui/core';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import ReactDOMServer from 'react-dom/server';
import { withOktaAuth } from '@okta/okta-react';
import {endpoint} from './mainEndpoint';

class ExportData extends Component {
    constructor(props) {
        super(props);

        this.state = {
            table_dir: this.props.table_dir,
            columns: this.props.columns,
            filters: this.props.filters,
            table_endpoint: this.props.table_endpoint,
            selectedFolder:this.props.selectedFolder? this.props.selectedFolder : -1,
            sort: this.props.sort,
            isOptionsDialogOpen: false,
            isOptionsDialogOpenCSV: false,
            isOptionsDialogOpenEmail:false,
            isEmailPreviewOpen: false,
            isEmailPreviewOpen1:false,
            emailHtml: '',
            buildingWeekOption: 'Building Week',
            hideColumnsExport:'true',
            emailExport:'true',
            emailAddresses: '',
        };
    }

    formatValue = (value, type, format) => {
        if (value === null) {
          return '';
        }
    
        if (type === 'number') {
          if (format === 'literal') {
            return value || ''; // If value is undefined, return an empty string
          } else if (format === 'percentChange') {
            const roundedValue = Math.round(value * 10) / 10;
            return (roundedValue || 0).toFixed(1) + '%'; // If value is undefined, return '0%'
          } else if (format === 'number') {
            const absValue = Math.abs(value);
            if (absValue >= 1e9) {
              return (value / 1e9 || 0).toFixed(1) + 'B'; // If value is undefined, return '0B'
            } else if (absValue >= 1e6) {
              return (value / 1e6 || 0).toFixed(1) + 'M'; // If value is undefined, return '0M'
            } else if (absValue >= 1e3) {
              return (value / 1e3 || 0).toFixed(1) + 'K'; // If value is undefined, return '0K'
            } else {
              return value || ''; // If value is undefined, return an empty string
            }
          }
        } else if (type === 'date') {
          const formattedDate = new Date(value).toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
          });
          return formattedDate;
        }
        return value;
      };

    generateCSVContent = (data) => {
        this.closeOptionsDialogCSV()
        const {hideColumnsExport} = this.state;
        const { columns } = this.props;
        var headerRow = []
        console.log(hideColumnsExport)
        var formattedData =[]
        if (hideColumnsExport === 'false') {
          headerRow = columns.map((column) => column.displayName);
          formattedData = data.map((row) =>
            columns.map((column) => {
              const formattedValue = this.formatValue(row[column.key], column.type, column.format);
              if (column.type === 'number' || column.type === 'percentChange') {
                return row[column.key]
              }
              return (column.type === 'string' || column.type === 'date') ? `"${formattedValue.replace(/"/g, '""')}"` : formattedValue;
      
            })
          );
        } else {
          console.log('filtering')
          headerRow = columns.filter((column) => !column.hide && !column.alwaysHide).map((column) => column.displayName);
          formattedData = data.map((row) =>
          columns
            .filter((column) => !column.hide && !column.alwaysHide)  // Filter out columns with hide=true
            .map((column) => {
              const formattedValue = this.formatValue(row[column.key], column.type, column.format);

              if (column.type === 'number' || column.type === 'percentChange') {
                return row[column.key];
              }

              return (column.type === 'string' || column.type === 'date') ? `"${formattedValue.replace(/"/g, '""')}"` : formattedValue;
            })
        );
        }

        


    
        const csvRows = [headerRow, ...formattedData];
        const csvContent = csvRows.map((row) => row.join(',')).join('\n');
        return csvContent;
      };

    exportDataToCSV = () => {
        // Perform the data export request with the updated filters
        const {data} = this.props
        
        // Generate the CSV content
        const csvContent = this.generateCSVContent(data);

        // Create a temporary anchor element
        const downloadLink = document.createElement('a');
        downloadLink.href = URL.createObjectURL(new Blob([csvContent], { type: 'text/csv' }));
        downloadLink.setAttribute('download', 'data.csv');
        downloadLink.click();

        // Clean up the temporary anchor element
        URL.revokeObjectURL(downloadLink.href);
            
    };

    

    


    openOptionsDialog = () => {
        this.setState({ isOptionsDialogOpen: true });
    }

    closeOptionsDialog = () => {
        this.setState({ isOptionsDialogOpen: false });
    }
    openOptionsDialogCSV = () => {
      this.setState({ isOptionsDialogOpenCSV: true });
  }
  openOptionsDialogEmail = () => {
      this.setState({ isOptionsDialogOpenEmail: true });
  }
  closeOptionsDialogEmail = () => {
    this.setState({ isOptionsDialogOpenEmail: false });
  } 

  closeOptionsDialogCSV = () => {
      this.setState({ isOptionsDialogOpenCSV: false });
  }

    openEmailPreview = () => {
        this.generateEmailHtml();
        this.setState({
            isEmailPreviewOpen: true,
            isOptionsDialogOpen: false, // Close the options dialog when opening email preview
        });
    }

    openEmailPreview1 = () => {
      // this.generateEmailHtml();
      console.log('openEmailPreview1')
      this.setState({
          isEmailPreviewOpen1: true,
          isOptionsDialogOpenEmail: false
          // isOptionsDialogOpen: false, // Close the options dialog when opening email preview
      });
  }



    handleEmailSelection = () => {
      const {emailExport} = this.state;
      console.log(emailExport)

      if (emailExport ==='true') {
        this.openOptionsDialog()
      } else {
        this.openEmailPreview1()
      }
    }

    closeEmailPreview = () => {
        this.setState({ isEmailPreviewOpen: false });
    };
    closeEmailPreview1 = () => {
      this.setState({ isEmailPreviewOpen1: false });
  };

    selectOption = (option) => {
        this.setState({ buildingWeekOption: option });
      }
    selectHideOption = (option) => {
      console.log(option)
      this.setState({ hideColumnsExport: option });
    }
    selectEmailOption = (option) => {
      console.log(option)
      console.log(this.state.emailExport)
      this.setState({ emailExport: option });
    }

    generateEmailHtml() {
        const {buildingWeekOption } = this.state;
        const {data,folders,selectedFolder} = this.props
        console.log(selectedFolder)
        console.log(data)
        console.log(folders)
        let folder_name = ''

        try {
            folder_name = folders.find(item => item.FOLDER_ID === selectedFolder)['FOLDER_NAME'];
            console.log(folder_name)
        } catch {
            folder_name = ''
        }

        // Generate the email HTML using React components
        const emailHtml = ReactDOMServer.renderToStaticMarkup(
            <html>
                <head>
                    <meta charset="UTF-8" />
                    <title>Email Template</title>
                    <style>
                        {`@media (max-width: 700px) {
                .box {
                  width: 100% !important;
                  display: block !important;
                }
              }`}
                    </style>
                </head>
                <body>
                    <div>
                        <table
                            width="100%"
                            border="0"
                            cellSpacing="0"
                            cellPadding="0"
                            style={{ maxWidth: '800px', margin: '0 auto' }}
                        >
                            <tr>
                                <td>
                                    <h1>{folder_name}</h1>
                                </td>
                            </tr>
                            {data.slice(0, 30).map((item, index) => (
                                <div>
                                    <tr style={{}}>
                                        <td align="center" style={{
                                            margin: '2px',
                                            borderBottom: '10px',
                                            backgroundColor: 'rgba(240, 240, 240, 0.25)', // Adjust the alpha value as needed
                                            padding: '10px',

                                        }}>
                                            <h3 style={{ textAlign: 'left', fontSize: '24px', fontWeight: 'bold', marginTop: '2px', marginBottom: '4px' }}>
                                                {item.ARTIST} - {item.TITLE}
                                            </h3>
                                            <h4 style={{ textAlign: 'left', color: '#777', fontSize: '14px', margin: '4px' }}>Released: {this.formatValue(item.RELEASE_DATE, 'date', 'date')}</h4>
                                            <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                                                <tr>
                                                    <td className="box" style={{ width: '45%' }}>
                                                        <div
                                                            style={{
                                                                borderRadius: '10px',
                                                                backgroundColor: '#f0f0f0',
                                                                margin: '5px',
                                                                padding: '5px',
                                                                height: '110px', // Adjust the height as needed
                                                            }}
                                                        >
                                                            <h4 style={{ textAlign: 'left', fontSize: '14px', margin: '0px' }}>Streaming </h4>
                                                            <div style={{ display: 'flex', fontSize: '14px', justifyContent: 'space-between', margin: '4px' }}>
                                                                <div style={{ width: '60%', fontSize: '14px', flexDirection: 'column', height: '100%' }}>
                                                                    {buildingWeekOption === 'Estimated Building Week' && (
                                                                        <div style={{ fontSize: '14px', marginBottom: '10px', whiteSpace: 'nowrap' }}>
                                                                            Estimated BW: {this.formatValue(item.WEEK_0, 'number', 'number')} {this.getArrowSymbol(item.WEEK_0_PCT_CHANGE)} ({this.formatValue(item.WEEK_0_PCT_CHANGE, 'number', 'percentChange')})
                                                                        </div>
                                                                    )}
                                                                    {buildingWeekOption === 'Building Week' && (
                                                                        <div style={{ fontSize: '14px', marginBottom: '10px', whiteSpace: 'nowrap' }}>Building Week: {this.formatValue(item.BUILDING_WEEK_STREAM_COUNT, 'number', 'number')} {this.getArrowSymbol(item.WEEK_0_PCT_CHANGE)} ({this.formatValue(item.WEEK_0_PCT_CHANGE, 'number', 'percentChange')})</div>
                                                                    )}
                                                                    <div style={{ fontSize: '14px', marginBottom: '10px', whiteSpace: 'nowrap' }}>This Week: {this.formatValue(item.WEEK_1, 'number', 'number')} {this.getArrowSymbol(item.WEEK_1_PCT_CHANGE)}  ({this.formatValue(item.WEEK_1_PCT_CHANGE, 'number', 'percentChange')})</div>
                                                                    <div style={{ fontSize: '14px', marginBottom: '10px', whiteSpace: 'nowrap' }}>Last Week: {this.formatValue(item.WEEK_2, 'number', 'number')}{this.getArrowSymbol(item.WEEK_2_PCT_CHANGE)}  ({this.formatValue(item.WEEK_2_PCT_CHANGE, 'number', 'percentChange')})</div>
                                                                </div>
                                                                <div style={{ textAlign: 'right', width: '40%', fontSize: '14px', flexDirection: 'column', height: '100%' }}>
                                                                    <div style={{ fontSize: '14px', marginBottom: '10px', whiteSpace: 'nowrap' }}>YTD: {this.formatValue(item.YTD, 'number', 'number')}</div>
                                                                    <div style={{ fontSize: '14px', marginBottom: '10px', whiteSpace: 'nowrap' }}>ATD: {this.formatValue(item.ATD, 'number', 'number')}</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className="box" style={{ width: '45%' }}>
                                                        <div
                                                            style={{
                                                                borderRadius: '10px',
                                                                backgroundColor: '#f0f0f0',
                                                                margin: '5px',
                                                                padding: '5px',
                                                                height: '110px', // Adjust the height as needed
                                                            }}
                                                        >
                                                            <h4 style={{ textAlign: 'left', fontSize: '14px', margin: '0px' }}>Equivalents </h4>
                                                            <div style={{ display: 'flex', fontSize: '14px', justifyContent: 'space-between', margin: '4px' }}>
                                                                <div style={{ width: '60%', fontSize: '14px', flexDirection: 'column', height: '100%' }}>
                                                                    <div style={{ fontSize: '14px', marginBottom: '10px', whiteSpace: 'nowrap' }}>This Week: {this.formatValue(item.SALES_WEEK_1, 'number', 'number')} {this.getArrowSymbol(item.SALES_WEEK_1_PCT_CHANGE)}  ({this.formatValue(item.SALES_WEEK_1_PCT_CHANGE, 'number', 'percentChange')})</div>
                                                                    <div style={{ fontSize: '14px', marginBottom: '10px', whiteSpace: 'nowrap' }}>Last Week: {this.formatValue(item.SALES_WEEK_2, 'number', 'number')}{this.getArrowSymbol(item.SALES_WEEK_2_PCT_CHANGE)}  ({this.formatValue(item.SALES_WEEK_2_PCT_CHANGE, 'number', 'percentChange')})</div>
                                                                </div>
                                                                <div style={{ textAlign: 'right', width: '40%', fontSize: '14px', flexDirection: 'column', height: '100%' }}>
                                                                    <div style={{ fontSize: '14px', marginBottom: '10px', whiteSpace: 'nowrap' }}>YTD: {this.formatValue(item.SALES_YTD, 'number', 'number')}</div>
                                                                    <div style={{ fontSize: '14px', marginBottom: '10px', whiteSpace: 'nowrap' }}>ATD: {this.formatValue(item.SALES_ATD, 'number', 'number')}</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </table>

                                        </td>
                                    </tr>
                                </div>
                            ))}

                        </table>
                    </div>
                </body>
            </html>
        );






        console.log(emailHtml)
        this.setState({ emailHtml });
    }
    getArrowSymbol(change) {
        return change > 0 ? <span style={{ color: 'green' }}>&#8599;</span> : change < 0 ? <span style={{ color: 'red' }}>&#8600;</span> : '';
    }

    handleEmailAddressesChange = (event) => {
        this.setState({
            emailAddresses: event.target.value,
        });
    };
    sendEmail1 = async () => {
      const { emailHtml } = this.state;
  
      // Get the input value from the ref
      const emailAddresses = this.emailInput.value;
      const emailTitle= this.emailTitle.value;
      
      const { authState, data, columns, filters,sort, table_endpoint, searchValue} = this.props;
      var filters_str = JSON.stringify(filters)
      const accessToken = authState.accessToken.accessToken;
      try {
        const response = await fetch(`${endpoint}/send_artist_table_email`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`, // Include the Bearer token here
          },
          body: JSON.stringify({
            emailHtml,
            emailAddresses,
            emailTitle,
            sort,
            columns,
            filters_str,
            table_endpoint,
            searchValue
          }),
        });
  
        if (response.ok) {
          console.log('Email sent successfully');
          this.setState({ isEmailPreviewOpen1: false }); // Close the email preview popup
          // No need to reset the emailAddresses state here
          this.setState({ isEmailSent: true }); // Set the flag to show the "Email Sent!" popup
        } else {
          console.error('Error sending email');
        }
      } catch (error) {
        console.error('Error sending email:', error);
      }
    };

    sendEmail = async () => {
        const { emailHtml } = this.state;
    
        // Get the input value from the ref
        const emailAddresses = this.emailInput.value;
        const emailTitle= this.emailTitle.value;

        const { authState } = this.props;
        const accessToken = authState.accessToken.accessToken;
        try {
          const response = await fetch(`${endpoint}/send_sendgrid_email`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${accessToken}`, // Include the Bearer token here
            },
            body: JSON.stringify({
              emailHtml,
              emailAddresses,
              emailTitle,
            }),
          });
    
          if (response.ok) {
            console.log('Email sent successfully');
            this.setState({ isEmailPreviewOpen: false }); // Close the email preview popup
            // No need to reset the emailAddresses state here
            this.setState({ isEmailSent: true }); // Set the flag to show the "Email Sent!" popup
          } else {
            console.error('Error sending email');
          }
        } catch (error) {
          console.error('Error sending email:', error);
        }
      };
    render() {
        const { emailExport,isOptionsDialogOpen,isOptionsDialogOpenCSV,isOptionsDialogOpenEmail, isEmailPreviewOpen,isEmailPreviewOpen1, emailHtml, buildingWeekOption, hideColumnsExport} = this.state;

        return (
            <PopupState variant="popover" popupId="demo-popup-menu">
                {(popupState) => (
                    <React.Fragment>
                        <Button variant="outlined" color="primary" {...bindTrigger(popupState)} style={{  }}>
                            Export
                        </Button>
                        <Menu {...bindMenu(popupState)}>
                            <MenuItem onClick={popupState.close}>
                                <Button onClick={this.openOptionsDialogCSV} variant="outlined" color="primary">
                                    CSV
                                </Button>
                            </MenuItem>
                            <MenuItem onClick={popupState.close}>
                                <Button onClick={this.openOptionsDialogEmail} variant="outlined" color="primary">
                                    Email
                                </Button>
                            </MenuItem>
                        </Menu>
                        <Dialog open={isOptionsDialogOpenCSV} onClose={this.closeOptionsDialogCSV}>
                        <DialogTitle>Select an Option</DialogTitle>
                        <DialogContent>
                          <p>Would you like to include hidden columns?</p>

                          <List>
                            <ListItem>
                              <RadioGroup
                                aria-label="hideColumnsExport"
                                name="hideColumnsExport"
                                value={hideColumnsExport}
                                onChange={(e) => this.selectHideOption(e.target.value)}
                              >
                                <FormControlLabel
                                  value={'true'}
                                  control={<Radio />}
                                  label="Hide Hidden Columns"
                                />
                                <FormControlLabel
                                  value={'false'}
                                  control={<Radio />}
                                  label="Show Hidden Columns"
                                />
                              </RadioGroup>
                            </ListItem>
                          </List>
                        </DialogContent>
                        <DialogActions>
                          <Button onClick={this.exportDataToCSV}>Confirm</Button>
                        </DialogActions>
                      </Dialog>
                      <Dialog open={isOptionsDialogOpenEmail} onClose={this.closeOptionsDialogEmail}>
                        <DialogTitle>Select an Option</DialogTitle>
                        <DialogContent>
                          <p>Select Email Type:</p>

                          <List>
                            <ListItem>
                              <RadioGroup
                                aria-label="hideColumnsExport"
                                name="hideColumnsExport"
                                value={emailExport}
                                onChange={(e) => this.selectEmailOption(e.target.value)}
                              >
                                <FormControlLabel
                                  value={'true'}
                                  control={<Radio />}
                                  label="Formatted Streaming Report"
                                />
                                <FormControlLabel
                                  value={'false'}
                                  control={<Radio />}
                                  label="Table Format"
                                />
                              </RadioGroup>
                            </ListItem>
                          </List>
                        </DialogContent>
                        <DialogActions>
                          <Button onClick={this.handleEmailSelection}>Confirm</Button>
                        </DialogActions>
                      </Dialog>



                        <Dialog open={isOptionsDialogOpen} onClose={this.closeOptionsDialog}>
                <DialogTitle>Select an Option</DialogTitle>
                <DialogContent>
                  <p>Would you like to include building week streams?</p>

                  <List>
                    <ListItem>
                      <RadioGroup
                        aria-label="building-week-options"
                        name="buildingWeekOptions"
                        value={buildingWeekOption}
                        onChange={(e) => this.selectOption(e.target.value)}
                      >
                        <FormControlLabel
                          value="Building Week"
                          control={<Radio />}
                          label="Building Week Streams"
                        />
                        <FormControlLabel
                          value="Estimated Building Week"
                          control={<Radio />}
                          label="Estimated Building Week Streams"
                        />
                        <FormControlLabel
                          value="None"
                          control={<Radio />}
                          label="No Building Week Streams"
                        />
                      </RadioGroup>
                    </ListItem>
                  </List>
                </DialogContent>
                <DialogActions>
                  <Button onClick={this.openEmailPreview}>Confirm</Button>
                </DialogActions>
              </Dialog>
                        <Dialog
                open={isEmailPreviewOpen}
                onClose={this.closeEmailPreview}
                maxWidth="lg" // Set the maxWidth property to limit the width
              >
                <DialogTitle style={{ width: '80vw' }}>Email Preview</DialogTitle>
                <DialogContent>
                  <div dangerouslySetInnerHTML={{ __html: emailHtml }} />
                </DialogContent>
                <DialogActions>
                <div style={{ display: 'flex', alignItems: 'center', flex: '1' }}>
                    <Typography variant="body1" style={{ marginRight: '8px' }}>
                      Title:
                    </Typography>
                    <TextField
                      label="Title"
                      variant="outlined"
                      size="small"
                      fullWidth
                      // Remove the value and onChange from the TextField
                      placeholder="Enter Email Title"
                      style={{ flex: '1' }} // Make the text field take up remaining space
                      inputRef={(input) => (this.emailTitle = input)} // Create a ref for the input
                    />
                  
                    <Typography variant="body1" style={{ marginRight: '8px' }}>
                      Send To:
                    </Typography>
                    <TextField
                      label="Emails"
                      variant="outlined"
                      size="small"
                      fullWidth
                      // Remove the value and onChange from the TextField
                      placeholder="Enter email addresses, comma-separated"
                      style={{ flex: '1' }} // Make the text field take up remaining space
                      inputRef={(input) => (this.emailInput = input)} // Create a ref for the input
                    />
                  </div>


                  <Button onClick={this.sendEmail}>Send Email</Button>
                </DialogActions>
              </Dialog>
              <Dialog
                            open={isEmailPreviewOpen1}
                            onClose={this.closeEmailPreview1}
                            maxWidth="lg" // Set the maxWidth property to limit the width
                          >
                            <DialogTitle style={{ width: '80vw' }}>Email Preview</DialogTitle>
                            {/* <DialogContent>
                              <div dangerouslySetInnerHTML={{ __html: emailHtml }} />
                            </DialogContent> */}
                            <DialogActions>
                                <div style={{ display: 'flex', alignItems: 'center', flex: '1' }}>
                                <Typography variant="body1" style={{ marginRight: '8px' }}>
                                  Title:
                                </Typography>
                                <TextField
                                  label="Title"
                                  variant="outlined"
                                  size="small"
                                  fullWidth
                                  // Remove the value and onChange from the TextField
                                  placeholder="Enter Email Title"
                                  style={{ flex: '1' }} // Make the text field take up remaining space
                                  inputRef={(input) => (this.emailTitle = input)} // Create a ref for the input
                                />
                              
                                <Typography variant="body1" style={{ marginRight: '8px' }}>
                                  Send To:
                                </Typography>
                                <TextField
                                  label="Emails"
                                  variant="outlined"
                                  size="small"
                                  fullWidth
                                  // Remove the value and onChange from the TextField
                                  placeholder="Enter email addresses, comma-separated"
                                  style={{ flex: '1' }} // Make the text field take up remaining space
                                  inputRef={(input) => (this.emailInput = input)} // Create a ref for the input
                                />
                              </div>
                              <Button onClick={this.sendEmail1}>Send Email</Button>
                            </DialogActions>
                          </Dialog>
                    </React.Fragment>
                )}
            </PopupState>
        );
    }
}

export default withOktaAuth(ExportData);
